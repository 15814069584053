import { render, staticRenderFns } from "./Home-mzg.vue?vue&type=template&id=56ea42e8&scoped=true&"
import script from "./Home-mzg.vue?vue&type=script&lang=js&"
export * from "./Home-mzg.vue?vue&type=script&lang=js&"
import style0 from "./Home-mzg.vue?vue&type=style&index=0&id=56ea42e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56ea42e8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VBtn,VCard,VCol,VContainer,VIcon,VRow,VSheet,VSlider})
